<template>
    <b-overlay :show="loading">
        <div>
            <b-row>
                <b-col sm="12">
                    <b-table-simple small resonsive borderless>
                        <b-tr>
                            <b-th width="25%">{{ $t('globalTrans.fiscal_year') }}</b-th>
                            <b-th width="2%">:</b-th>
                            <b-td>
                                {{ getFiscalYearName(detailsData.fiscal_year_id) }}
                            </b-td>
                        </b-tr>
                        <b-tr v-if="detailsData.factory_id">
                            <b-th>{{ $t('teaGardenConfig.factory_name') }}</b-th>
                            <b-th>:</b-th>
                            <b-td>
                                {{ getFactoryName(detailsData.factory_id) }}
                            </b-td>
                        </b-tr>
                        <b-tr v-if="detailsData.garden_id">
                            <b-th>{{ $t('teaGardenConfig.garden_name') }}</b-th>
                            <b-th>:</b-th>
                            <b-td>
                                {{ getGardenName(detailsData.garden_id) }}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th>{{ $t('teaGardenConfig.zone') }}</b-th>
                            <b-th>:</b-th>
                            <b-td>
                                {{ getZoneName(detailsData.zone_id) }}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th>{{ $t('teaGardenConfig.broker_name') }}</b-th>
                            <b-th>:</b-th>
                            <b-td>
                                {{ getBrokerName(detailsData.broker_id) }}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th>{{ $t('stock_management.transfer_date') }}</b-th>
                            <b-th>:</b-th>
                            <b-td>
                                {{ detailsData.transfer_date | dateFormat }}
                            </b-td>
                        </b-tr>
                    </b-table-simple>
                    <b-table-simple bordered hover small responseive class="mt-3">
                        <b-thead>
                            <b-tr>
                                <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th>{{ $t('teaGardenConfig.made_tea_type') }}</b-th>
                                <b-th>{{ $t('teaGarden.transfer_quantity') }}</b-th>
                                <b-th>{{ $t('globalTrans.remarks') }}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(item, index) in detailsData.details" :key="index">
                                <b-td>{{ $n(index + 1) }}</b-td>
                                <b-td>{{ getTeaTypeName(item.tea_type_id) }}</b-td>
                                <b-td class="text-right">{{ $n(item.transfer_quantity) }}</b-td>
                                <b-td>{{ item.remarks }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="2"><strong>{{ $t('globalTrans.grand_total') }}</strong></b-td>
                                <b-td class="text-right"><strong>{{ $n(getTotalQty()) }}</strong></b-td>
                                <b-td></b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col md="12" class="text-right">
                    <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-details')">{{ $t('globalTrans.cancel') }}</b-button>
                </b-col>
            </b-row>
        </div>
    </b-overlay>
</template>
<script>
    import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
    import { taskAssignShow } from '../../api/routes'
    // import Pdf from './details-pdf'
    export default {
        name: 'Details',
        props: ['id'],
        data () {
            return {
                teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
                detailsData: {
                    details: []
                }
            }
        },
        created () {
            this.detailsData = this.getFormData()
        },
        computed: {
            currentLocale () {
                return this.$i18n.locale
            },
            loading () {
                return this.$store.state.commonObj.loading
            }
        },
        methods: {
            getFormData () {
                const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.id))
                return JSON.parse(JSON.stringify(tmpData))
            },
            // pdfExport () {
            //     const reportTitle = this.$t('teaGardenPanel.small_tea_garden_registration')
            //     Pdf.exportPdfDetails(teaGardenServiceBaseUrl, '/configuration/report-heading/detail', 5, reportTitle, this)
            // },
            async pdfExport () {
                const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.id })
                const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
                if (service !== undefined) {
                    if (service.office_type_id) {
                        params.office_type_id = service.office_type_id
                    }
                    if (service.office_id) {
                        params.office_id = service.office_id
                    }
                }
                this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
                const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, taskAssignShow + '/' + this.id, params)
                var blob = new Blob([result], {
                    type: 'application/pdf'
                })
                var url = window.URL.createObjectURL(blob)
                window.open(url).print()
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            },
            getFiscalYearName (id) {
                const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getFactoryName (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getGardenName (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getTeaTypeName (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.masterMadeTeaTypeList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getZoneName (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.zoneList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getBrokerName (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.masterBrokerInfoList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getTotalQty () {
                let totalQty = 0
                this.detailsData.details.forEach(item => {
                    totalQty += parseFloat(item.transfer_quantity)
                })
                return totalQty
            }
        }
    }
</script>
<style scoped>
    .tag {
        font-weight: 600;
        border-left: 4px solid #1c4261;
        padding-left: 6px;
    }
    .download-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 2px 4px;
    }
    .yes-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
    }
    .no-btn {
        color: red;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
        margin-right: 33px !important;
    }
    .tag {
        font-weight: 600;
        border-left: 4px solid #1c4261;
        padding-left: 6px;
    }
    .download-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 2px 4px;
    }
    .yes-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
    }
    .no-btn {
        color: red;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
        margin-right: 33px !important;
    }

    .stepper-wrapper {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .stepper-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
    .stepper-item::before {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: -50%;
        z-index: 2;
    }

    .stepper-item::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 2;
    }

    .stepper-item .step-counter {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ccc;
        margin-bottom: 6px;
        color: white;
    }

    .stepper-item.active {
        font-weight: bold;
    }

    .stepper-item.completed .step-counter {
        background-color: #4bb543;
        color:white;
    }

    .stepper-item.completed::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #4bb543;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 3;
    }

    .stepper-item:first-child::before {
        content: none;
    }
    .stepper-item:last-child::after {
        content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
    .tagTwo {
        position: relative;
        display: inline-block;
        border-radius: 6px;
        clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
        background: hsl(250deg, 100%, 40%);
        padding: 5px 25px;
        font-weight: 600;
        font-size: 12px;
        color: #FFF;
        transition: clip-path 500ms;
    }
    .tagTwo:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: hsl(252deg, 100%, 60%);
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 6px 0;
        transition: transform 500ms;
    }
</style>
